import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, InputAdornment } from '@mui/material';
import { joinMeeting } from './MeetingRoom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import KeyboardIcon from '@mui/icons-material/Keyboard'; // Replace KeypadIcon with DialpadIcon
  

const JoinMeeting = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [serverUrl, setServerUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [meetingUrl, setMeetingUrl] = useState('');

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get('https://langmeet.ai/api/get-csrf/', { withCredentials: true });
        console.log('CSRF Token fetched:', response.data.csrfToken);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    getCsrfToken();
  }, []);

  const handleStartMeeting = async () => {
    const meetingData = await joinMeeting(csrfToken, setToken, setServerUrl, setErrorMessage, null);

    if (meetingData) {
      const { token, url: serverUrl, room_name } = meetingData;
      navigate(`/meeting/${room_name}`, { state: { token, serverUrl } });
    } else {
      setErrorMessage('Failed to start the meeting. Please try again.');
    }
  };

  const handleJoinMeeting = async () => {
    try {
      const roomName = meetingUrl.split('/').pop();
      if (roomName) {
        const meetingData = await joinMeeting(csrfToken, setToken, setServerUrl, setErrorMessage, roomName);
        const { token, url: serverUrl, room_name } = meetingData;
        navigate(`/meeting/${room_name}`, { state: { token, serverUrl } });
      } else {
        setErrorMessage('Invalid meeting URL. Please enter a valid URL.');
      }
    } catch (error) {
      setErrorMessage('Invalid URL format. Please enter a valid meeting URL.');
    }
  };
  
  return (
    <Box
      sx={{
        p: 10,
        textAlign: 'center',
        bgcolor: 'primary.main',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" gutterBottom>
        Break Language Barriers. Connect Globally.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Start a meeting with live translation now!
      </Typography>
  
      {errorMessage && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
  
      {/* Start Meeting and Join Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 3 }}>
        {/* Start Meeting Button */}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<VideoCallIcon />}
          onClick={handleStartMeeting}
          sx={{
            px: 3,
            fontSize: '1rem',
            textTransform: 'none',
            height: '40px',
            bgcolor: 'secondary.main', // Same color for both buttons
            '&:hover': {
              bgcolor: 'secondary.dark', // Hover effect for "New Meeting"
            },
          }}
        >
          New Meeting
        </Button>
  
        {/* Meeting URL Input */}
        <TextField
          placeholder="Enter a code or link"
          variant="outlined"
          value={meetingUrl}
          onChange={(e) => setMeetingUrl(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyboardIcon />
              </InputAdornment>
            ),
            sx: {
              height: '40px', // Adjust the height here
              '& .MuiInputBase-input': { padding: '8px 14px' }, // Adjust the padding to reduce height
            },
          }}
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            width: '250px',
            height: '40px',
          }}
        />
  
        {/* Join Button */}
        <Button
          variant="contained"
          disabled={!meetingUrl}
          onClick={handleJoinMeeting}
          sx={{
            fontSize: '1rem',
            textTransform: 'none',
            px: 3,
            height: '40px',
            // fontWeight: 'normal',
            bgcolor: meetingUrl ? 'secondary.main' : 'gray', // Same color as "New Meeting"
            color: meetingUrl ? 'white' : 'gray',
            cursor: meetingUrl ? 'pointer' : 'not-allowed',
            '&:hover': {
              bgcolor: meetingUrl ? 'secondary.dark' : 'gray', // Same hover effect as "New Meeting"
            },
          }}
        >
          Join
        </Button>
      </Box>
    </Box>
  );
};
export default JoinMeeting;
