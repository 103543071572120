// src/components/SignUp.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios'; // Import axios to handle HTTP requests
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Fetch CSRF token when the component mounts
  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get('https://langmeet.ai/api/get-csrf/', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);  // Save the CSRF token
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    getCsrfToken();
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(''); // Clear error messages
    setSuccess(''); // Clear success messages

    // Set up the data for the POST request
    const data = {
      username: name,
      email: email,
      password: password,
    };

    try {
      const response = await axios.post('https://langmeet.ai/api/register/', data, {
        headers: {
          'X-CSRFToken': csrfToken, // Attach the CSRF token
        },
        withCredentials: true, // Send cookies with the request
      });

      // Check the response and update the UI accordingly
      if (response.status === 201) {
        setSuccess('User created successfully!');
        setName('');
        setEmail('');
        setPassword('');

        // Redirect to login page after successful sign-up
        setTimeout(() => {
          navigate('/login'); // Redirect to login page
        }, 1500); // Wait 1.5 seconds before redirecting
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.error || 'Something went wrong. Please try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: '2rem auto',
        padding: '2rem',
        border: '1px solid #ccc',
        borderRadius: '8px',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Sign Up
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      <form onSubmit={handleSignUp}>
        <TextField
          fullWidth
          label="Name"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Password"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Sign Up
        </Button>
      </form>
    </Box>
  );
};

export default SignUp;
