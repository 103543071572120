import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '@livekit/components-styles';
import axios from 'axios';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  useRoomContext,
} from '@livekit/components-react';
import { Track } from 'livekit-client';

const MeetingRoom = () => {
  const { roomName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token;
  const serverUrl = location.state?.serverUrl;

  if (!token || !serverUrl) {
    return <div>Error: Unable to join the room. Please try again.</div>;
  }

  const handleLeave = () => {
    navigate('/');
  };

  return (
    <LiveKitRoom
      video={true}
      audio={false}
      token={token}
      serverUrl={serverUrl}
      connectOptions={{
        publishDefaults: { audio: false, video: true },
      }}
      data-lk-theme="default"
      style={{ height: '100vh', backgroundColor: 'black' }}
      onDisconnected={handleLeave}
    >
      <h1
        style={{
          color: 'white',
          backgroundColor: 'black',
          margin: '0px',
          textAlign: 'center',
          lineHeight: '2rem',
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Room: {roomName}
      </h1>
      <MyVideoConference />
      <RoomAudioRenderer />
      <ControlBar />
      <CustomAudioPublisher />
    </LiveKitRoom>
  );
};

function MyVideoConference() {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  );
  return (
    <GridLayout
      tracks={tracks}
      style={{ height: 'calc(100vh - var(--lk-control-bar-height) - 2rem)' }}
    >
      <ParticipantTile />
    </GridLayout>
  );
}

function CustomAudioPublisher() {
  const room = useRoomContext();
  const [roomConnected, setRoomConnected] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const wsRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const processorNodeRef = useRef(null);
  const playAudioBufferRef = useRef(null);

  useEffect(() => {
    if (!room) return;

    const handleConnected = () => {
      setRoomConnected(true);
    };

    if (room.state === 'connected') {
      handleConnected();
    } else {
      room.on('connected', handleConnected);
    }

    return () => {
      room.off('connected', handleConnected);
    };
  }, [room]);

  useEffect(() => {
    if (roomConnected && !isMuted) {
      startAudioProcessing();
    }

    return () => {
      stopAudioProcessing();
    };
  }, [roomConnected, isMuted]);

  const startAudioProcessing = async () => {
    try {
      // Initialize WebSocket
      wsRef.current = new WebSocket('wss://langmeet.ai/ws/translation/');
      wsRef.current.binaryType = 'arraybuffer';

      wsRef.current.onopen = () => {
        console.log('WebSocket connection established');
      };

      wsRef.current.onmessage = async (event) => {
        // Receive processed audio data from backend and play it
        const arrayBuffer = event.data;
        playProcessedAudio(arrayBuffer);
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket connection closed');
      };

      // Capture audio from the user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContextRef.current = audioContext;

      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      const sourceNode = audioContext.createMediaStreamSource(stream);
      sourceNodeRef.current = sourceNode;

      // Create a ScriptProcessorNode to process audio data
      const processorNode = audioContext.createScriptProcessor(4096, 1, 1);
      processorNodeRef.current = processorNode;

      processorNode.onaudioprocess = (event) => {
        const inputBuffer = event.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);

        // Convert Float32Array to Int16Array
        const int16Data = floatTo16BitPCM(inputData);

        // Send audio data to backend over WebSocket
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(int16Data);
        }
      };

      // Connect the audio nodes
      sourceNode.connect(processorNode);
      processorNode.connect(audioContext.destination);
    } catch (error) {
      console.error('Error during audio processing:', error);
      alert('An error occurred while processing audio: ' + error.message);
    }
  };

  const stopAudioProcessing = () => {
    if (processorNodeRef.current) {
      processorNodeRef.current.disconnect();
      processorNodeRef.current = null;
    }

    if (sourceNodeRef.current) {
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  };

  const floatTo16BitPCM = (input) => {
    const output = new Int16Array(input.length);
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }
    return output.buffer;
  };

  const playProcessedAudio = (arrayBuffer) => {
    const audioContext = audioContextRef.current;
    if (!audioContext) return;

    // Assuming the backend sends raw PCM data
    audioContext.decodeAudioData(arrayBuffer, (decodedData) => {
      const bufferSource = audioContext.createBufferSource();
      bufferSource.buffer = decodedData;
      bufferSource.connect(audioContext.destination);
      bufferSource.start(0);
    }, (error) => {
      console.error('Error decoding audio data:', error);
    });
  };
  return null;
}

export default MeetingRoom;

export const joinMeeting = async (
  csrfToken,
  setToken,
  setServerUrl,
  setError,
  room_name
) => {
  try {
    const response = await axios.post(
      'https://langmeet.ai/api/get_meeting_token/',
      {
        room_name: room_name,
      },
      {
        headers: { 'X-CSRFToken': csrfToken },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      const data = response.data;
      setToken(data.token);
      setServerUrl(data.url);
      return data;
    } else {
      setError('Failed to start the meeting. Please try again.');
      return false;
    }
  } catch (error) {
    setError('Something went wrong. Please try again.');
    return false;
  }
};
