import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  
  const navigate = useNavigate(); // Initialize the navigate hook
  const { login } = useAuth();
  
  // Fetch CSRF token when the component mounts
  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get('https://langmeet.ai/api/get-csrf/', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);  // Save the CSRF token
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    getCsrfToken();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Make the POST request to the backend's login endpoint
      const response = await axios.post('https://langmeet.ai/api/login/', {
        email: email,
        password: password,
      }, {
        headers: {
          'X-CSRFToken': csrfToken,  // Include the CSRF token in the request headers
        },
        withCredentials: true  // Include credentials (for session management)
      });

      // Handle the response
      if (response.status === 200) {
        setSuccessMessage('Login successful!');
        login();
        navigate('/'); // Redirect to the home page after successful login
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error || 'Login failed');
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '2rem auto', padding: '2rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="primary">{successMessage}</Typography>}
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Password"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;
